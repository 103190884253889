<style scoped>
.main-content-container {
    overflow: auto;
    max-width: 100%;
}

.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 30px 0px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.search-bar {
    margin-bottom: 20px;
    position: relative;
}

.search-bar input {
    width: calc(100% - 30px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

.clear-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color: #E33222;
    color: #fff;
    padding: 3px 5px;
    border-radius: 7px;
}

.add-user-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px;
}

.add-user-button button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
    border: 1px solid #2895D2;
}

.add-user-button button:hover {
    background-color: #fff;
    color: #2895D2;
    border: 1px solid #2895D2;
}

.users-list,
.add-user-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.fixed-table {
    table-layout: auto;
}

.users-table {
    margin-top: 40px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.users-table th,
.users-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    width: auto;
    /* O podrías probar con porcentajes, por ejemplo, width: 10%; */
    white-space: normal;
    overflow: hidden;
    /* Ocultar cualquier desbordamiento */
    text-overflow: ellipsis;
    /* Agregar puntos suspensivos para indicar desbordamiento */
}

.buttons-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.users-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.users-table tbody tr:hover {
    background-color: #e0e0e0;
}

.details,
.edit-button {
    margin-bottom: 5px;
}

.details {
    cursor: pointer;
    background-color: #2895D2;
    color: #fff;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 14px;
    white-space: normal;
}

.details:hover {
    background-color: #144663;
}

.edit-button {
    cursor: pointer;
    background-color: #F3A225;
    color: #000000;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 14px;
    white-space: normal;
}

.delete {
    cursor: pointer;
    background-color: #E33222;
    color: #fff;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 14px;
    white-space: normal;
}

.delete:hover {
    background-color: #811e15;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.pagination button {
    margin: 0px 5px;
}

.page-numbers {
    display: flex;
}

.pagination button,
.page-numbers button {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
}

.page-numbers button.active {
    background-color: #F3A225;
    color: #fff;
    border: 1px solid #F3A225;
}

/* Estilos para el formulario */
.user-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

.user-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Agrega estas reglas para estilizar el select */
.form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    /* Ajusta el margen superior según sea necesario */
    width: 100%;
    /* Hace que el select ocupe el ancho completo del contenedor */
    background-color: #fff;
    /* Color de fondo del select */
    color: #333;
    /* Color del texto del select */
}

/* Estilo para los bordes del select cuando está enfocado */
.form-group select:focus {
    outline: none;
    /* Elimina el contorno predeterminado del select al enfocar */
    border-color: #2895D2;
    /* Color del borde al enfocar */
    box-shadow: 0 0 5px rgba(40, 149, 210, 0.5);
    /* Sombra alrededor del select al enfocar */
}

/* Estilo para las opciones del select */
.form-group select option {
    /* Ajusta el estilo de las opciones según sea necesario */
}

.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

.button-container button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

.error-message {
    color: red;
    margin-top: 5px;
}

@media screen and (max-width: 780px) {

    .add-user-form,
    .users-list {
        margin: 5px;
    }
}

@media screen and (max-width: 768px) {
    .main-content-container {
        margin-top: 80px;
    }

    .users-table thead,
    .users-table tbody,
    .users-table th,
    .users-table td,
    .users-table tr {
        display: block;
    }

    .users-table th,
    .users-table td {
        text-align: left;
    }

    .users-table th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .users-table tr {
        margin-bottom: 15px;
    }

    .users-table td {
        border: none;
        border-bottom: 1px solid #ccc;
        position: relative;
        padding-left: 50%;
    }

    .users-table td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-label);
        font-weight: bold;
    }

    .users-table td:last-child {
        border-bottom: none;
        padding-left: 0;
        /* Evitar que el último elemento tenga espacio a la izquierda */
    }
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading && !showForm" class="users-list">
            <AlertsConfirmationComponent :show="showDeleteAlert" :message="deleteAlertMessage"
                @accept="confirmDeleteUser" @cancel="cancelDeleteUser" />
            <h1>{{ title }}</h1>
            <!-- Buscador -->
            <div class="search-bar">
                <input v-model="keySearch" @keyup.enter="searchUsers" placeholder="Buscar usuario..." />
                <button v-if="keySearch" @click="clearSearch" class="clear-button">X</button>
            </div>
            <div class="add-user-button">
                <button @click.prevent="openUserForm">Añadir Usuario</button>
            </div>
            <table class="users-table fixed-table">
                <thead>
                    <tr>
                        <!-- <th @click="sort('id')" style="cursor: pointer">ID</th> -->
                        <th @click="sort('first_name')" style="cursor: pointer">Usuario/Email</th>
                        <!-- <th @click="sort('last_name')" style="cursor: pointer">Apellidos</th> -->
                        <th @click="sort('zip_code')" style="cursor: pointer">Código Postal</th>
                        <th>Discapacidad</th>
                        <th @click="sort('created_at')" style="cursor: pointer">Fecha de Nacimiento</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users.value" :key="user.id">
                        <td data-label="Nombre">
                            <div style="display: flex; flex-direction: column">
                                <span style="margin-bottom: 5px">{{ user.first_name }}</span>
                                <span style="margin-bottom: 5px">{{ user.email }}</span>
                            </div>
                        </td>
                        <td data-label="Localidad">{{ user.zip_code }}</td>
                        <td data-label="Discapacidad">
                            <template v-if="user.isDisabled">
                                Sí - {{ user.disability }}
                            </template>
                            <template v-else>
                                No
                            </template>
                        </td>
                        <td data-label="Creado el">{{ formatDateTime(user.birthdate) }}</td>
                        <td>
                            <div class="buttons-column">
                                <template v-if="inputPasswordSelected !== user.id">
                                    <button v-on:click="changePassword(user)" type="button" class="details">CAMBIAR
                                        CONTRASEÑA</button>
                                </template>
                                <template v-else>
                                    <input v-model="user.newPassword" type="password" placeholder="Nueva contraseña" />
                                    <button v-on:click="sendChangePassword(user.id, user.newPassword)" type="button"
                                        class="details" style="margin-left: 10px">CAMBIAR</button>
                                </template>
                                <button v-on:click="editUser(user.id)" type="button" class="edit-button">EDITAR</button>
                                <button v-on:click="deleteUser(user.id)" type="button" class="delete">ELIMINAR</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginación -->
            <div class="pagination">
                <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Anterior</button>
                <div class="page-numbers">
                    <button v-for="page in Math.ceil(totalItems / perPage)" :key="page" @click="changePage(page)"
                        :class="{ active: page === currentPage }">{{ page }}</button>
                </div>
                <button @click="changePage(currentPage + 1)"
                    :disabled="currentPage === Math.ceil(totalItems / perPage)">Siguiente</button>
            </div>
        </div>

        <div v-if="!loading && showForm" class="add-user-form">
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <form @submit.prevent="addUser">
                <div class="admin-grid">
                    <div class="admin-card">
                        <div class="form-group">
                            <label for="name">Nombre de Usuario</label>
                            <input v-model="userName" type="text" id="first_name" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Email</label>
                            <input v-model="userEmail" type="email" id="email" />
                        </div>
                        <div class="form-group">
                            <label for="long">Contraseña</label>
                            <input v-model="userPassword" type="password" id="password" />
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword">Repetir Contraseña</label>
                            <input v-model="confirmPassword" type="password" id="confirmPassword" />
                            <p v-if="passwordsDoNotMatch" class="error-message">Las contraseñas no coinciden.</p>
                        </div>
                        <div class="form-group">
                            <label for="locality">Código Postal</label>
                            <input v-model="userZipCode" type="text" id="zipcode" />
                        </div>
                        <div class="form-group">
                            <label for="birthdate">Fecha de Nacimiento</label>
                            <input v-model="userBirthDate" type="date" id="birthdate" :max="getCurrentDate()" />
                        </div>
                        <div class="form-group">
                            <label for="isDisabled">¿Tiene discapacidad?</label>
                            <div style="margin-top: 20px">
                                <label>
                                    <input v-model="userIsDisabled" type="radio" value="true" id="isDisabledYes" />
                                    Sí
                                </label>
                                <label>
                                    <input v-model="userIsDisabled" type="radio" value="false" id="isDisabledNo" />
                                    No
                                </label>
                            </div>
                        </div>

                        <div v-if="userIsDisabled" class="form-group">
                            <label for="disability">Discapacidad</label>
                            <select v-model="userDisability" id="disability">
                                <option value="Física u orgánica">Física u Orgánica</option>
                                <option value="Sensorial">Sensorial</option>
                                <option value="Intelectual">Intelectual</option>
                                <option value="Mental">Enfermedad Mental</option>
                                <option value="Ninguna">Ninguna</option>
                            </select>
                        </div>



                    </div>
                </div>
                <div class="button-container">
                    <button type="submit" :disabled="loading">Añadir Usuario</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import router from '../routes';
import AlertComponent from '../components/AlertsComponent.vue'
import AlertsConfirmationComponent from '../components/AlertsConfirmationComponent.vue';

let loading = ref(true)
let showForm = ref(false)
let showDeleteAlert = ref(false)
let deleteAlertMessage = ref('')
let deleteUserToDelete = ref(null)

let inputPasswordSelected = ref()

let currentPage = ref(1)
let perPage = ref(10)
let totalItems = ref(0)
let order_by = ref('id')
let order = ref('DESC')
let keySearch = ref('');

let userName = ref('')
let userLastName = ref('')
let userEmail = ref('')
let userPassword = ref('')
let confirmPassword = ref('')
let passwordsDoNotMatch = ref(false)
let userZipCode = ref('')
let userBirthDate = ref('')
let userIsDisabled = ref(false)
let userDisability = ref('')


const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Listado de Usuarios'
const users = [];

const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Los meses empiezan desde 0
    let dd = today.getDate();

    if (mm < 10) {
        mm = '0' + mm;
    }

    if (dd < 10) {
        dd = '0' + dd;
    }

    return yyyy + '-' + mm + '-' + dd;
}

// Método para mostrar la alerta de confirmación antes de eliminar al usuario
const deleteUser = (id) => {
    // Configura el mensaje de la alerta y muestra la alerta
    deleteAlertMessage.value = '¿Estás seguro de que deseas eliminar este usuario?'
    showDeleteAlert.value = true
    // Guarda el ID del usuario a eliminar para usarlo más tarde
    deleteUserToDelete.value = id
}

// Método para confirmar la eliminación del usuario
const confirmDeleteUser = () => {
    // Oculta la alerta
    showDeleteAlert.value = false
    // Ejecuta la eliminación del usuario
    if (deleteUserToDelete.value) {
        deleteUserRequest(deleteUserToDelete.value)
    }
}

// Método para cancelar la eliminación del usuario
const cancelDeleteUser = () => {
    // Oculta la alerta y restablece el ID del usuario a eliminar
    showDeleteAlert.value = false
    deleteUserToDelete.value = null
}

// Método para realizar la solicitud de eliminación del usuario
const deleteUserRequest = (id) => {
    loading.value = true
    axios.post('/api/admin/delete-user/' + id, '', {
        headers: headers
    })
        .then(response => {
            fetchUsers()
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al eliminar al administrador:', error);
        });
}

const goBack = () => {
    showForm.value = false
}

const formatDateTime = (dateTime) => {
    if (dateTime) {
        const date = new Date(dateTime);
        return date.toLocaleDateString();
    }
    return '';
}

const changePage = (page) => {
    currentPage.value = page;
    fetchUsers();
}

const openUserForm = () => {
    showForm.value = true
}

const fetchUsers = () => {
    loading.value = true
    axios.get('/api/admin/user-list', {
        headers,
        params: {
            page: currentPage.value,
            per_page: perPage.value,
            order_by: order_by.value,
            order: order.value,
            keySearch: keySearch.value
        }
    })
        .then(res => {
            users.value = res.data.users.data;
            users.value.forEach(a => {
                a.newPassword = null
            });
            totalItems.value = res.data.users.total;
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la lista de playas:', error);
        });
}

const searchUsers = () => {
    currentPage.value = 1;
    fetchUsers();
}

const clearSearch = () => {
    keySearch.value = '';
    fetchUsers();
};

const sort = (field) => {
    if (field === order_by.value) {
        order.value = order.value === 'ASC' ? 'DESC' : 'ASC';
    } else {
        order_by.value = field;
        order.value = 'ASC';
    }
    fetchUsers();
}

const changePassword = (user) => {
    inputPasswordSelected.value = user.id;
}

const sendChangePassword = (userID, newPassword) => {
    loading.value = true
    axios.post('/api/admin/edit-user', {
        id: userID,
        newPassword: newPassword
    }, {
        headers: headers
    })
        .then(response => {
            inputPasswordSelected.value = null;
            fetchUsers()
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al cambiar la contraseña:', error);
        });
}

const addUser = () => {
    loading.value = true

    const name = userName.value
    const lastName = userLastName.value
    const email = userEmail.value
    const password = userPassword.value
    const confirmPasswordValue = confirmPassword.value
    const birthdate = userBirthDate.value
    const zipCode = userZipCode.value
    const isDisabled = userIsDisabled.value
    const disability = userDisability.value

    // Validar que las contraseñas coincidan
    if (password !== confirmPasswordValue) {
        passwordsDoNotMatch.value = true
        loading.value = false
        showForm.value = true
        return
    }

    passwordsDoNotMatch.value = false

    // Crear formData y agregar campos
    const formData = new FormData()
    formData.append('first_name', name)
    formData.append('last_name', lastName)
    formData.append('email', email)
    formData.append('password', password)
    formData.append('birthdate', birthdate)
    formData.append('zip_code', zipCode)
    formData.append('isDisabled', isDisabled)
    formData.append('disability', disability)

    // Realizar la petición POST con formData
    axios.post('/api/admin/add-user', formData, {
        headers: headers,
    })
        .then(response => {

            console.log('Usuario añadido con éxito:', response.data)

            userName.value = ''
            userLastName.value = ''
            userEmail.value = ''
            userPassword.value = ''
            confirmPassword.value = ''
            userBirthDate.value = ''
            userZipCode.value = ''
            userIsDisabled.value = false
            userDisability.value = ''
            showForm.value = false
            fetchUsers()
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al añadir el usuario:', error);
        });

}

const editUser = (id) => {
    router.push('/panel/detalle-usuario/' + id);
}

fetchUsers();

</script>
