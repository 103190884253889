<style scoped>
    .main-content-container{
        overflow: auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container, .loader {
        height: 100vh;
    }
    .logo {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0rem .0625rem .25rem;
        border-radius: .25rem;
        padding: 1.25rem;
        margin: 1.25rem .3125rem 2.5rem .3125rem;
    }
    .logo img {
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .login-box {
        box-shadow: rgba(0, 0, 0, 0.16) 0rem .0625rem .25rem;
        background-color: #fff;
        padding: 1.25rem;
        border-radius: .25rem;
        margin: .3125rem;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 3.75rem;
    }

    label {
        font-size: 1rem;
        margin-bottom: .375rem;
        display: block;
    }

    input {
        padding: .625rem;
        margin-bottom: .75rem;
        font-size: .875rem;
        border: .0625rem solid #ccc;
        border-radius: .25rem;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #2895D2;
        color: #fff;
        padding: .75rem;
        font-size: 1rem;
        border: none;
        border-radius: .25rem;
        cursor: pointer;
        margin-top: 2.5rem;
        transition: all 300ms;
    }

    button:hover {
        background-color: #E33222;
    }

    .error-box{
        width: 100%;
        padding: 1.25rem;
        border-radius: .4375rem;
        background-color: #b91c1c;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        font-size: 1.25rem;
    }
    @media screen and (max-width: 47.9375rem) {
        .login-container, .loader {
            height: auto;
        }
    }
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
        <div v-if="!loading" class="login-container">
            <div class="logo">
                <img src="images/logofamdif.png" alt="">
            </div>
            <div class="login-box">
                <h1>{{ title }}</h1>
                <p>Este proceso tendrá que ser autorizado por la dirección de FAMDIF para evitar suplantaciones.</p>
                <div class="error-box" v-if="data.error.show">
                    <span class="error">{{ data.error.message }}</span>
                </div>
                <form @submit.prevent="sendForm">
                    <div>
                        <label for="email">Introduce tu correo electrónico</label>
                        <input type="email" id="email" v-model="data.formData.email" required>
                    </div>
                    <button type="submit">Tramitar la solicitud de cancelación</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, ref } from 'vue'
    import axios from 'axios'
    import store from '../store'
    import router from '../routes.js'
    import AlertComponent from '../components/AlertsComponent.vue'

    let loading = ref(false)
    let showAlert = ref(false)
    let alertMessage = ref('')

    let title = 'Cancelación de cuenta.'
    const data = reactive({
        formData: {
            email: '',
        },
        error: {
            show: false,
            message: '',
        },
    });

    const showAlerts = (message) => {
        showAlert.value = true
        alertMessage.value = message
    }

    const cerrarAlerta = () => {
        showAlert.value = false;
    }

    const sendForm = async () => {
        loading.value = true

        try {
            const response = await axios.post(store.state.baseApi + 'cancel-account', data.formData);

            if (response.data.error) {
                data.error.show = true
                data.error.message = response.data.error
                showAlerts(response.data.error.message)
                loading.value = false
            } else {
                loading.value = false
                showAlerts('¡Lamentamos que quieras irte! Hemos recibido tu solicitud. En breve nos pondremos en contacto contigo para confirmar la cancelación por seguridad.')
            }
        } catch (error) {
            loading.value = false
            data.error.show = true
            data.error.message = error.message
            console.error('Error al iniciar sesión', error);
        }
    };

</script>

