<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

    :root {
        --main: #F29727;
        --blue: #2BA8D9;
        --red: #D92323;
        --dark-blue: #3A4E8C;
    }

    *{
        font-family: 'Montserrat', sans-serif;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .main-container{
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

</style>

<template>
    <Suspense>
        <Content />
    </Suspense>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import router from '../routes.js';
    import axios from 'axios';
    import store from '../store';

    const route = useRoute();

    const token = localStorage.getItem('famdifAdmin');
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    let isAdmin = null;

    onMounted(() => {
        setTimeout(() => {
            if ((route.path !== '/recuperar-contrasena' && route.path !== '/request-account-deletion' && route.path !== '/politica-de-privacidad') && !route.path.startsWith('/reset-password/')) {
                try {
                    const response = axios.get(store.state.baseApi + 'check-admin', { headers }).then(res => {
                        console.log('entra')
                        isAdmin = res.data.isAdmin;
                        if(!isAdmin){
                            router.push('/login')
                        }
                    })
                } catch (error) {
                    console.error('Error al verificar el estado de administrador:', error);
                }
            }
        }, 300);
    });

</script>

