<style scoped>
    .main-content-container{
        overflow: auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container, .loader {
        height: 100vh;
    }
    .logo {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 4px;
        padding: 20px;
        margin: 20px 5px 40px 5px;
        display: flex;
        justify-content: center;
    }
    .logo img {
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    h3, p, ul{
        margin-bottom: 20px;
    }
    ul, ol{
        margin-left: 40px;
    }
    .privacy-text {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        margin: 50px;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    label {
        font-size: 16px;
        margin-bottom: 6px;
        display: block;
    }

    input {
        padding: 10px;
        margin-bottom: 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #2895D2;
        color: #fff;
        padding: 12px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 40px;
        transition: all 300ms;
    }

    button:hover {
        background-color: #E33222;
    }

    .error-box{
        width: 100%;
        padding: 20px;
        border-radius: 7px;
        background-color: #b91c1c;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        .login-container, .loader {
            height: auto;
        }
    }
</style>

<template>
    <div class="main-content-container">
        <div class="privacy-text">
            <div class="logo">
                <img src="images/logofamdif.png" alt="">
            </div>
            <br>
            <h1 style="text-transform: uppercase; text-align: center">{{ title }}</h1>
            <br><br>
            <h3>¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE TUS DATOS PERSONALES?</h3>
            <p>FAMDIF/COCEMFE-Murcia, la Federación de Asociaciones Murcianas de Personas con Discapacidad Física y/u Orgánica de la Región de Murcia, con NIF: G30052518, domicilio social: C/Mariano Montesinos 14 (30005) Murcia y correo electrónico: <a href="mailto:famdif@famdif.org">famdif@famdif.org</a>.</p>

            <h3>¿CÓMO TE PUEDES PONER EN CONTACTO CON EL DELEGADO DE PROTECCIÓN DE DATOS DE FAMDIF/COCEMFE-MURCIA?</h3>
            <p>Podrás contactar con el Delegado de Protección de Datos de FAMDIF/COCEMFE-Murcia en la siguiente dirección de correo electrónico: <a href="mailto:dpd@famdif.org">dpd@famdif.org</a></p>

            <h3>¿QUÉ DATOS PERSONALES TUYOS TRATA FAMDIF/COCEMFE-MURCIA?</h3>
            <p>Con ocasión de tu relación con nosotros, FAMDIF/COCEMFE-Murcia puede tratar las siguientes categorías de datos:</p>
            <ul>
                <li>Datos identificativos y de contacto, incluida dirección de correo electrónico.</li>
                <li>Códigos o claves de identificación para acceso y operativa en los canales que utilizas en tu relación con FAMDIF/COCEMFE-Murcia.</li>
                <li>Datos sociodemográficos tales como localidad de residencia.</li>
                <li>Y en su caso, datos de categoría especial, relativos a la discapacidad y el tipo, así como el grado de autonomía (si requiere o no de acompañante, dato que se obtiene a través del cuestionario voluntario).</li>
            </ul>
            <p>Desde FAMDIF/COCEMFE-Murcia te pedimos que mantengas tus datos debidamente actualizados para garantizar que en todo momento los datos que tratamos son veraces. Si los modificas, comunícanoslo para que estemos al corriente de tu situación actual.</p>
            <h3>¿PARA QUÉ TRATAMOS TUS DATOS PERSONALES?</h3>
            <ol>
                <li>
                    <p>Gestionar los servicios que nos solicites con la aplicación ACCEDE de FAMDIF/COCEMFE-Murcia</p>
                    <ul>
                        <li>Enviarte notificaciones no comerciales para gestionar tu relación con la aplicación ACCEDE de FAMDIF/COCEMFE-Murcia. Esto incluye notificaciones tales como: avisos sobre incidencias, avisos con información importante sobre novedades de la aplicación, actualizaciones sobre nuevos puntos de interés y/o actualización de grado de accesibilidad de lugares ya existentes. Para tal fin realizamos envíos de correos electrónicos. Te informamos que puedes revocar tu consentimiento para recibir comunicaciones pulsando el enlace que se inserta para tal fin en todas las comunicaciones que remitimos a través del correo electrónico.</li>
                        <li>Controlar, analizar y gestionar situaciones de riesgo y/o fraude.</li>
                        <li>Gestionar y analizar incidencias, quejas, sugerencias o reclamaciones que podamos recibir.</li>
                    </ul>
                </li>
                <li>
                    <p>Mejorar la calidad de los servicios, y realizar acciones como las indicadas a continuación para poder incrementar tu grado de satisfacción como cliente.</p>
                    <ul>
                        <li>Atender tus solicitudes de quejas, sugerencias y/o incidencias.</li>
                        <li>Mejorar los servicios de la aplicación ACCEDE de FAMDIF/COCEMFE-Murcia.</li>
                        <li>Realizar estadísticas, cálculos, medias y/o estudios que puedan ser de interés para FAMDIF/COCEMFE-Murcia.</li>
                    </ul>
                </li>
            </ol>
            <p>Atendiendo a las finalidades anteriormente indicadas, conservaremos tus datos conforme a los plazos señalados en la legislación que resulte de aplicación en función de los tratamientos realizados o, en su caso, hasta que solicites la supresión de tus datos o manifiestes tu oposición a dichos tratamientos.</p>

            <h3>¿POR QUÉ RAZÓN UTILIZAMOS TUS DATOS PERSONALES? (BASE LEGAL)</h3>
            <ul>
                <li>Atender tus solicitudes de incidencias, quejas, sugerencias o reclamaciones.</li>
                <li>En base a tu consentimiento, para enviarte comunicaciones por medios electrónicos.</li>
                <li>En base a la legislación que es de aplicación a FAMDIF/COCEMFE-Murcia, para cumplir con nuestras obligaciones legales.</li>
            </ul>

            <h3>¿CÓMO HEMOS OBTENIDO TUS DATOS?</h3>
            <p>Los datos que tratamos para las finalidades anteriormente indicadas, los hemos obtenido directamente de ti a través de los formularios que hemos puesto a tu disposición en nuestra aplicación ACCEDE de FAMDIF/COCEMFE-Murcia.</p>

            <h3>¿QUIÉNES RECIBIRÁN TUS DATOS?</h3>
            <p>Los únicos datos que serán cedidos a terceros serán los obtenidos para elaborar estadísticas, cálculos, medias y/o estudios. Serán utilizados, sin carácter particular, a modo de memorias, publicaciones y/o informes técnicos y podrán ser compartidos con las Administraciones Públicas, entidades sociales privadas o públicas, y/o de manera pública a través de los distintos canales digitales y redes sociales de los que dispone FAMDIF/COCEMFE-Murcia. Todo ello con el fin de atender la misión con la que nacimos:
                “Tenemos un carácter esencialmente reivindicativo, así como prestador de servicios en la medida que las administraciones no los generen. Nacimos como respuesta a las inquietudes de las asociaciones que la componen, actualmente diecisiete, como medio de aunar esfuerzos para lograr sus objetivos comunes: lograr la integración social y cultural de las personas con discapacidad y su participación activa y normalizada en la sociedad.
                Promovemos la intervención ante los problemas sociales y en especial ante aquellos que incumben más directamente a las personas con discapacidad física y orgánica, por sus especiales características. Pretendemos alcanzar la eliminación de barreras arquitectónicas y sociales; el acceso a la cultura, al trabajo, al deporte y a la salud, entre otros.”</p>

            <h3>¿QUÉ DERECHOS TIENES?</h3>
            <p><p>Puedes ejercer los derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad de datos ante FAMDIF/COCEMFE-Murcia, mediante el envío de un correo electrónico a <a href="mailto:dpd@famdif.org">dpd@famdif.org</a>. De igual modo, queremos recordarte que siempre podrás cambiar o suprimir fácilmente tus datos, en el sitio habilitado para ello en la aplicación ACCEDE de FAMDIF/COCEMFE-Murcia.</p></p>
        </div>
    </div>
</template>

<script setup>
    let title = 'Política de privacidad - APP "ACCEDE"'

</script>

