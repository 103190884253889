<style scoped>
    .main-content-container{
        overflow: auto;
        max-width: 100%;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .go-back {
        width: fit-content;
        font-size: 14px;
        text-transform: uppercase;
        background-color: #E33222;
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        margin: 30px 0px;
        transition: all 300ms;
    }

    .go-back:hover {
        background-color: #9b2419;
    }
    .add-admin-button {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 40px 0px;
    }
    .add-admin-button button {
        background-color: #2895D2;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        transition: all 300ms;
        border: 1px solid #2895D2;
    }
    .add-admin-button button:hover {
        background-color: #fff;
        color: #2895D2;
        border: 1px solid #2895D2;
    }
    .admins-list, .add-admin-form{
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 100%;
        margin: 50px;
    }
    .fixed-table {
        table-layout: auto;
    }
    .admins-table {
        margin-top: 40px;
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
    }

    .admins-table th, .admins-table td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
        width: auto; /* O podrías probar con porcentajes, por ejemplo, width: 10%; */
        white-space: normal;
        overflow: hidden; /* Ocultar cualquier desbordamiento */
        text-overflow: ellipsis; /* Agregar puntos suspensivos para indicar desbordamiento */
    }
    .buttons-column {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-self: stretch;
        justify-content: center;
        align-items: center;
    }
    .admins-table tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .admins-table tbody tr:hover {
        background-color: #e0e0e0;
    }
    .details, .edit-button{
        margin-bottom: 5px;
    }
    .details{
        cursor: pointer;
        background-color: #2895D2;
        color: #fff;
        padding: 4px 8px;
        border: none;
        border-radius: 4px;
        transition: all 300ms;
        font-size: 14px;
        white-space: normal;
    }

    .details:hover{
        background-color: #144663;
    }

    .delete{
        cursor: pointer;
        background-color: #E33222;
        color: #fff;
        padding: 4px 8px;
        border: none;
        border-radius: 4px;
        transition: all 300ms;
        font-size: 14px;
        white-space: normal;
    }

    .delete:hover{
        background-color: #811e15;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

    }

    .pagination button{
        margin: 0px 5px;
    }
    .page-numbers {
        display: flex;
    }

    .pagination button, .page-numbers button {
        background-color: #fff;
        color: #333;
        border: 1px solid #ccc;
        padding: 5px 10px;
        cursor: pointer;
    }

    .page-numbers button.active {
        background-color: #F3A225;
        color: #fff;
        border: 1px solid #F3A225;
    }
    .shop-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    /* Estilos para el formulario */
    .admin-card {
        border: 1px solid #ccc;
        padding: 20px;
        border-radius: 4px;
        grid-column: span 1;
    }

    .admin-form {
        width: 100%;
        margin: 0 auto;
    }

    /* Estilos para los grupos de formulario (label encima del input) */
    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .form-group label {
        font-weight: bold;
    }

    .form-group input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 50px 0px;
    }

    .button-container button {
        background-color: #2895D2;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        transition: all 300ms;
    }
    .error-message {
        color: red;
        margin-top: 5px;
    }
    @media screen and (max-width: 780px) {
        .add-admin-form, .admins-list{
            margin: 5px;
        }
    }
    @media screen and (max-width: 768px) {
        .main-content-container{
            margin-top: 80px;
        }

        .admins-table thead,
    .admins-table tbody,
    .admins-table th,
    .admins-table td,
    .admins-table tr {
        display: block;
    }

    .admins-table th,
    .admins-table td {
        text-align: left;
    }

    .admins-table th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .admins-table tr {
        margin-bottom: 15px;
    }

    .admins-table td {
        border: none;
        border-bottom: 1px solid #ccc;
        position: relative;
        padding-left: 50%;
    }

    .admins-table td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-label);
        font-weight: bold;
    }

    .admins-table td:last-child {
        border-bottom: none;
        padding-left: 0; /* Evitar que el último elemento tenga espacio a la izquierda */
    }
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading && !showForm" class="admins-list">
            <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
            <AlertsConfirmationComponent :show="showDeleteAlert" :message="deleteAlertMessage" @accept="confirmDeleteAdmin" @cancel="cancelDeleteAdmin" />
            <h1>{{ title }}</h1>
            <div class="add-admin-button">
                <button @click.prevent="openAdminForm">Añadir Administrador</button>
            </div>
            <table class="admins-table fixed-table">
                <thead>
                    <tr>
                        <th @click="sort('id')" style="cursor: pointer">ID</th>
                        <th @click="sort('first_name')" style="cursor: pointer">Nombre</th>
                        <th @click="sort('last_name')" style="cursor: pointer">Apellidos</th>
                        <th @click="sort('email')" style="cursor: pointer">Email</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="admin in admins.value" :key="admin.id">
                        <td data-label="ID">{{ admin.id }}</td>
                        <td data-label="Nombre">{{ admin.first_name }}</td>
                        <td data-label="Apellidos">{{ admin.last_name }}</td>
                        <td data-label="Email">{{ admin.email }}</td>
                        <td>
                            <div class="buttons-column">
                                <template v-if="inputPasswordSelected !== admin.id">
                                    <button v-on:click="changePassword(admin)" type="button" class="details">CAMBIAR CONTRASEÑA</button>
                                </template>
                                <template v-else>
                                    <input v-model="admin.newPassword" type="password" placeholder="Nueva contraseña" />
                                    <button v-on:click="sendChangePassword(admin.id, admin.newPassword)" type="button" class="details" style="margin-left: 10px">CAMBIAR</button>
                                </template>
                                <button v-on:click="deleteAdmin(admin.id)" type="button" class="delete">ELIMINAR</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginación -->
            <div class="pagination">
                <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Anterior</button>
                <div class="page-numbers">
                    <button
                        v-for="page in Math.ceil(totalItems / perPage)"
                        :key="page"
                        @click="changePage(page)"
                        :class="{ active: page === currentPage }"
                    >{{ page }}</button>
                </div>
                <button @click="changePage(currentPage + 1)" :disabled="currentPage === Math.ceil(totalItems / perPage)">Siguiente</button>
            </div>
        </div>

        <div v-if="!loading && showForm" class="add-admin-form">
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <form @submit.prevent="addAdmin">
                <div class="admin-grid">
                    <div class="admin-card">
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input v-model="adminName" type="text" id="first_name" />
                        </div>
                        <div class="form-group">
                            <label for="locality">Apellidos</label>
                            <input v-model="adminLastName" type="text" id="last_name" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Email</label>
                            <input v-model="adminEmail" type="email" id="email" />
                        </div>
                        <div class="form-group">
                            <label for="long">Contraseña</label>
                            <input v-model="adminPassword" type="password" id="password" />
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword">Repetir Contraseña</label>
                            <input v-model="confirmPassword" type="password" id="confirmPassword" />
                            <p v-if="passwordsDoNotMatch" class="error-message">Las contraseñas no coinciden.</p>
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit" :disabled="loading">Añadir Administrador</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import axios from 'axios'
    import { ref } from 'vue'
    import router from '../routes';
    import AlertComponent from '../components/AlertsComponent.vue'
    import AlertsConfirmationComponent from '../components/AlertsConfirmationComponent.vue';

    let loading = ref(true)
    let showForm = ref(false)
    let showDeleteAlert = ref(false)
    let deleteAlertMessage = ref('')
    let deleteUserToDelete = ref(null)
    let showAlert = ref(false)
    let alertMessage = ref('')

    let inputPasswordSelected = ref()

    let currentPage = ref(1)
    let perPage = ref(10)
    let totalItems = ref(0)
    let order_by = ref('id')
    let order = ref('DESC')

    let adminName = ref('')
    let adminLastName = ref('')
    let adminEmail = ref('')
    let adminPassword = ref('')
    let confirmPassword = ref('')
    let passwordsDoNotMatch = ref(false)

    const token = localStorage.getItem('famdifAdmin');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const title = 'Listado de Administradores'
    const admins = [];

    const showAlerts = (message) => {
    showAlert.value = true
    alertMessage.value = message
}

const cerrarAlerta = () => {
    showAlert.value = false;
}

    // Método para mostrar la alerta de confirmación antes de eliminar al usuario
const deleteAdmin = (id) => {
    // Configura el mensaje de la alerta y muestra la alerta
    deleteAlertMessage.value = '¿Estás seguro de que deseas eliminar este administrador?'
    showDeleteAlert.value = true
    // Guarda el ID del usuario a eliminar para usarlo más tarde
    deleteUserToDelete.value = id
}

// Método para confirmar la eliminación del usuario
const confirmDeleteAdmin = () => {
    // Oculta la alerta
    showDeleteAlert.value = false
    // Ejecuta la eliminación del usuario
    if (deleteUserToDelete.value) {
        deleteUserRequest(deleteUserToDelete.value)
    }
}

// Método para cancelar la eliminación del usuario
const cancelDeleteAdmin = () => {
    // Oculta la alerta y restablece el ID del usuario a eliminar
    showDeleteAlert.value = false
    deleteUserToDelete.value = null
}

// Método para realizar la solicitud de eliminación del usuario
const deleteUserRequest = (id) => {
    loading.value = true
        axios.post('/api/admin/delete-admin/' + id, '',{
            headers: headers
        })
        .then(response => {
            console.log(response.data.error)
            if(response.data.error){
                loading.value = false
                showAlerts(response.data.error)
            } else {
                fetchAdmins()
            }
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al eliminar al administrador:', error);
        });
}

    const goBack = () => {
        showForm.value = false
    }

    const formatDateTime = (dateTime) => {
        if (dateTime) {
            const date = new Date(dateTime);
            return date.toLocaleDateString();
        }
        return '';
    }

    const openAdminForm = () => {
        showForm.value = true
    }

    const changePage = (page) => {
        currentPage.value = page;
        fetchAdmins();
    }

    const fetchAdmins = () => {
        loading.value = true
        axios.get('/api/admin/admin-list', {
            headers,
            params: {
                page: currentPage.value,
                per_page: perPage.value,
                order_by: order_by.value,
                order: order.value,
            }
        })
        .then(res => {
            admins.value = res.data.admins.data;
            admins.value.forEach(a => {
                a.newPassword = null
            });
            totalItems.value = res.data.admins.total;
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la lista de playas:', error);
        });
    }

    const sort = (field) => {
        if (field === order_by.value) {
            order.value = order.value === 'ASC' ? 'DESC' : 'ASC';
        } else {
            order_by.value = field;
            order.value = 'ASC';
        }
        fetchAdmins();
    }

    const changePassword = (admin) => {
        inputPasswordSelected.value = admin.id;
    }

    const sendChangePassword = (adminID, newPassword) => {
        loading.value = true
        axios.post('/api/admin/edit-admin', {
            id: adminID,
            newPassword: newPassword
        }, {
            headers: headers
        })
        .then(response => {
            inputPasswordSelected.value = null;
            fetchAdmins()
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al cambiar la contraseña:', error);
        });
    }

    const addAdmin = () => {
        loading.value = true

        const name = adminName.value;
        const lastName = adminLastName.value;
        const email = adminEmail.value;
        const password = adminPassword.value;
        const confirmPasswordValue = confirmPassword.value;

        // Validar que las contraseñas coincidan
        if (password !== confirmPasswordValue) {
            passwordsDoNotMatch.value = true
            loading.value = false
            showForm.value = true
            return
        }

        passwordsDoNotMatch.value = false;

        // Crear formData y agregar campos
        const formData = new FormData();
        formData.append('first_name', name);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('password', password);

        // Realizar la petición POST con formData
        axios.post('/api/admin/add-admin', formData, {
            headers: headers,
        })
        .then(response => {

            console.log('Administrador añadido con éxito:', response.data);

            adminName.value = '';
            adminLastName.value = '';
            adminEmail.value = '';
            adminPassword.value = '';
            confirmPassword.value = '';
            showForm.value = false
            fetchAdmins()
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al añadir el administrador:', error);
        });

    }

    fetchAdmins();

</script>
